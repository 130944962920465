.login-button {
  color: white;
  padding: 0.5em;
  border-radius: 0.3em;
  width: fit-content;
  font-size: 3vmin;
}
.login-button:hover {
  cursor: pointer;
}
.login-button:active {
  background-color: rgb(79, 75, 128);
}
