.comment-text {
  margin-right: 1em;
  padding: var(--internal-comment-padding);
  justify-content: center;
  justify-self: center;
  height: fit-content;

  /* font-size: 17px; */
}
.comment-text.jumbo {
  /* font-size: xx-large; */
  margin-right: 0;
}
