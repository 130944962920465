.responses {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  /* max-height: 70vh; */
  /* max-height: 400pt; */
  overflow-y: auto;
}
