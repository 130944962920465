.scussion-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* overflow: hidden; */
  height: 100vh;
}
.prompts-and-title {
  /* position: relative; */
}

.comments-container-on-page {
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 180pt;
  width: 100vw;
  max-width: 450pt;
}
::-webkit-scrollbar {
  width: 6px;
  border-radius: 2px;
  background: rgb(97, 97, 97);
}
::-webkit-scrollbar-thumb {
  background: rgb(162, 162, 162);
}
.section-title {
  font-size: larger;
  color: rgba(164, 164, 164, 0.536);
  padding: 0.2em;
  font-style: italic;
}
