.focused-comment {
  color: #d3d3d3;
  /* margin: 1em 0 1.4em; */
  padding: 0.4em 0.8em;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* font-weight: bold; */
  font-size: x-large;
  width: fit-content;
  max-width: 450pt;
  border-radius: 0.5em;
}
