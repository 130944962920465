.comment-prompts {
  font-size: 80%;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.comment-prompt {
  margin: 0.2em;
  padding: 0.3em;
  border-radius: 0.3em;
  color: rgb(159, 159, 159);
  background-color: rgba(138, 138, 138, 0.154);
  cursor: active;
}
.comment-prompt.me {
  font-size: 75%;
  background-color: transparent;
}
.comment-prompt:active:not(.me) {
  background-color: rgb(74, 58, 255);
}
