.add-comment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 5em;
  width: 100%;
  color: rgb(159, 158, 158);
}
.submit-comment {
  font-size: large;
  transform: rotate(-90deg);
  color: rgb(145, 145, 145);
  margin: -4px;
  cursor: pointer;
  border-radius: 50%;
}
.submit-comment:active {
  color: rgb(172, 131, 255);
  /* rgb(174, 161, 255); */
}
.addComment {
  text-align: center;
  /* outline: rgb(59, 59, 59) solid 3px; */
  /* background-color: transparent; */

  /* justify-self: bottom; */
  text-align: left;
  color: gray;
  /* bottom: 5pt; */
  /* max-width: 420pt; */
  /* box-shadow: 0 0 30px; */
  /* background-color: rgb(62, 62, 75) !important; */
  position: relative;
}
.addComment:hover {
  cursor: text;
}
.addComment:active {
  /* background-color: var(--comment-background-color); */
}
