:root {
  --comment-background-color: rgba(243, 243, 243, 0.04);
  --prompt-background-color: rgba(243, 243, 243, 0.1);
  --internal-comment-padding: 2px;
}
.comments {
  font-size: 18px;
  margin: 0em 0.5em 0;
  height: fit-content;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  overflow-y: visible;
  /* height: 400pt; */

  /* justify-self: flex-start; */
}
