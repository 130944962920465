.text-and-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5px;
  /* margin: 6px; */
}
.text-and-buttons.isFocus {
  /* justify-content: center; */
}
.comment {
  padding: 0.3em;
  margin: 0.5em;
  background-color: var(--comment-background-color);
  box-shadow: 0 0 2px 0px rgba(142, 140, 140, 0.7);
  border-radius: 0.4em;
  width: 92%;
  color: rgb(220, 220, 220);
  text-align: left;
  cursor: pointer;
  /* display: inline-flex; */
}
.comment-container {
}
.comment:active:not(.isFocus) {
  background-color: rgb(16, 0, 189);
}
/* Going back */
.comment.isFocus {
  background-color: transparent;
  /* box-shadow: none; */
}
.comment.hasResponses {
  /* outline-width: px; */
  /* box-shadow: 0 0px 6px gray; */
  /* outline-color: rgb(91, 91, 91); */
  /* font-weight: bold; */
}
