#prompts {
  padding: 0.5em;
  margin: 0.5em;

  height: fit-content;
  /* max-height: 300pt; */
  overflow-y: auto;
  overflow-x: hidden;
  /* flex: 1; */
}
#prompts > .comments {
  /* margin-top: 15%; */
}
